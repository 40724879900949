@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

 body {
  margin: 0;
  padding: 0;
  /* background-image: linear-gradient(357deg, #fdfdfd 1%, #50e8ff); */
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
  background-color:'#4cf58a';
  /* background-image: url('../src/assets/amico.png');
  background-position: center; 
  background-repeat: no-repeat; */
}


